import Layout from '../components/Layout'
import Type from '../components/Type'
import ExampleLayout from '../components/ExampleLayout'

const MutationComponent = ({ pageContext, location }) => {
  const { mutation, title } = pageContext

  return (
    <Layout location={location} crumbLabel={title}>
      <ExampleLayout exampleName={mutation.field.name}>
        <h1>{mutation.field.name}</h1>
        <p>{mutation.field.description}</p>
        <Type type={mutation.inputType} showRequired={true} />
        <Type type={mutation.payloadType} />
      </ExampleLayout>
    </Layout>
  )
}

export default MutationComponent
