import { Link } from 'gatsby'
import { useBreadcrumb } from 'gatsby-plugin-breadcrumb'
import styled from 'styled-components'

const Container = styled.div`
  margin: 15px;
`

const Breadcrumb = ({ location, crumbLabel }) => {
  const { crumbs } = useBreadcrumb({
    location: location || {},
    crumbLabel,
  })

  if (!location || crumbs.length <= 1) {
    return <Container>&nbsp;</Container>
  }

  return (
    <Container>
      {crumbs.slice(-3, -1).map(({ crumbLabel, pathname }, index) => {
        return (
          <span key={index}>
            {index > 0 ? ' > ' : ''}
            <Link to={pathname}>{crumbLabel}</Link>
          </span>
        )
      })}
    </Container>
  )
}

export default Breadcrumb
