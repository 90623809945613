import Layout from './Layout'
import styled from 'styled-components'

const Markdown = ({ children }) => (
  <Layout>
    <Content>{children}</Content>
  </Layout>
)

const Content = styled.div`
  width: 800px;
  margin: 0 auto;
`

export default Markdown
