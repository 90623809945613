/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import ExampleQuery from '../../components/ExampleQuery';
import JsonObjectPath from '../../components/JsonObjectPath';
import Layout from '../../components/Markdown';
const MDXLayout = Layout;
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    span: "span",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "querying-shipment-details"
  }, "Querying shipment details", React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#querying-shipment-details"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  }))), "\n", React.createElement(_components.p, null, "You may need to get a detailed shipments data in order to be able to reconcile it on your end (for example). The query may look something like this:"), "\n", React.createElement(ExampleQuery, {
    name: "queryShipments",
    limitHeight: true
  }));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
