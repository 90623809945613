import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Type from '../components/Type'
import ExampleLayout from '../components/ExampleLayout'

const TypeComponent = ({ pageContext, data, location }) => {
  const type = pageContext.type

  return (
    <Layout location={location} crumbLabel={type.name}>
      <ExampleLayout exampleName={type.name}>
        <h1>{type.name}</h1>
        <Type type={type} descriptionHtml={data.markdownRemark?.html} />
        {(type.connectionTypes || []).map((type) => (
          <Type key={type.name} type={type} />
        ))}
      </ExampleLayout>
    </Layout>
  )
}

export default TypeComponent

export const query = graphql`
  query MarkdownTypeDescription($typePathGlob: String) {
    markdownRemark(fileAbsolutePath: { glob: $typePathGlob }) {
      html
    }
  }
`
