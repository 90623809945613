/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import ExampleQuery from '../../components/ExampleQuery';
import JsonObjectPath from '../../components/JsonObjectPath';
import Layout from '../../components/Markdown';
const MDXLayout = Layout;
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    span: "span",
    p: "p",
    code: "code",
    pre: "pre",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "replacing-contact-lens-prescriptions"
  }, "Replacing Contact Lens Prescriptions", React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#replacing-contact-lens-prescriptions"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  }))), "\n", React.createElement(_components.p, null, "Replacing contact lens prescriptions creates a new prescription in a draft state. For guidance on creating a prescription,\nplease refer to the API documentation available ", React.createElement(_components.a, {
    href: "/types/Mutation/createContactLensPrescription"
  }, "here"), ". The provided variables\nshould include the public ID of the prescription the user would like to replace under the new ", React.createElement(_components.code, null, "prescriptionToReplaceId"), " field."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n  ...\n  \"prescribingLocationId\": \"Location-9R3TMR8XMV4\",\n  \"prescriptionToReplaceId\": \"ContactLensPrescription-PBWINNXQG4M\",\n  \"createdById\": \"StaffMember-WVNFD3KPVRD\"\n  ...\n}\n")), "\n", React.createElement(_components.h2, {
    id: "1-get-the-confirmation-url"
  }, "1. Get the confirmation URL", React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#1-get-the-confirmation-url"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  }))), "\n", React.createElement(_components.p, null, "Once the request is submitted to our API to create a new prescription including the ", React.createElement(_components.code, null, "prescriptionToReplaceId"), " field,\nthe API responds with a confirmation URL."), "\n", React.createElement(ExampleQuery, {
    name: "queryReplacementConfirmationUrl",
    limitHeight: true
  }), "\n", React.createElement(_components.h2, {
    id: "2-navigate-to-the-confirmation-page"
  }, "2. Navigate to the confirmation page", React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#2-navigate-to-the-confirmation-page"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  }))), "\n", React.createElement(_components.p, null, "The PMS user navigates to the provided URL, which directs them to a confirmation page within the Starfish app.\nThe confirmation page displays the old prescription alongside the new one."), "\n", React.createElement(_components.h2, {
    id: "3-cancel-or-approve-the-replacement"
  }, "3. Cancel or approve the replacement", React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#3-cancel-or-approve-the-replacement"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  }))), "\n", React.createElement(_components.p, null, "The PMS user has the option to either cancel or approve the replacement displayed on the confirmation page."), "\n", React.createElement(_components.p, null, "If the old prescription includes a subscription, once the staff user verifies the validity of the new prescription,\nthey are directed to the existing subscription approval page. There, they can confirm their satisfaction with the new payments."), "\n", React.createElement(_components.p, null, "If the old prescription does not include a subscription, the process concludes with the staff user verifying the validity of the new prescription."), "\n", React.createElement(_components.p, null, "Once all details are confirmed, the status of the new prescription changes from draft to approved replacing the original prescription."));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
