/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import IlgImageNoStyle from '../images/ilg_logo_og.svg'
import Breadcrumb from '../components/Breadcrumb'

import Header from './Header'
import './layout.scss'
import Menu from './Menu'

const MenuContent = styled.div`
  display: flex;
`

const IlgImage = styled(IlgImageNoStyle)`
  width: 400px;
`

const Sidebar = styled.div`
  background-color: #f5f2f0;
  margin-right: 20px;
  flex: 0 1 auto;
  width: 300px;
`

const Content = styled.div`
  flex: 1;
`

const Footer = styled.footer`
  margin-top: 2em;
  color: gray;
  text-align: right;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 2em;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a {
    color: inherit;
  }
`

const Container = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr auto;
`

const Layout = styled(({ className, children, location, crumbLabel }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Container className={className}>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <Breadcrumb location={location} crumbLabel={crumbLabel} />
      <MenuContent>
        <Sidebar>
          <Menu />
        </Sidebar>
        <Content>
          <main>{children}</main>
        </Content>
      </MenuContent>
      <Footer>
        <a href="https://www.indigolighthouse.com/">
          <IlgImage />
        </a>
      </Footer>
    </Container>
  )
})`
  font-family: Montserrat, sans-serif;
  min-height: 100vh;
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
