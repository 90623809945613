import styled from 'styled-components'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-bottom: 15px;
`

const StyledSyntaxHighlighter = styled(SyntaxHighlighter)`
  flex: 1 1 auto;
`

const Code = ({ ...props }) => {
  return <StyledSyntaxHighlighter style={undefined} {...props} />
}

const CodeContainer = styled.div`
  flex: 0 1 0px;
  display: flex;
  position: relative;
  flex-direction: column;

  ${({ limitHeight }) => (limitHeight ? `max-height: 300px;` : ``)}
`

const CodeTitle = styled.h3`
  top: 0px;
  position: absolute;
  text-align: right;
  right: 0px;
  font-size: 19px;
  color: #9e9e9e7a;
  background-color: transparent;
  margin-top: 12px;
  margin-right: 7px;
`

const ExampleWebhook = ({ className, name, ignoreLoadError = false, limitHeight }) => {
  let example

  try {
    example = require(`../generated/examples/${name}.json`)
  } catch (e) {
    if (e.code === 'MODULE_NOT_FOUND' && ignoreLoadError) {
      return null
    } else {
      throw e
    }
  }

  const headerKeys = Object.keys(example.headers).sort()
  const renderedHeaders = headerKeys.map((key) => `${key}: ${example.headers[key]}`).join('\n')

  return (
    <Container className={className}>
      <CodeContainer limitHeight={limitHeight}>
        <CodeTitle>Webhook Request</CodeTitle>
        <Code language="http">{`POST ${example.url}`}</Code>
      </CodeContainer>
      <CodeContainer limitHeight={limitHeight}>
        <CodeTitle>Headers</CodeTitle>
        <Code language="http">{renderedHeaders}</Code>
      </CodeContainer>
      <CodeContainer limitHeight={limitHeight}>
        <CodeTitle>Body</CodeTitle>
        <Code language="json">{JSON.stringify(example.body, null, 2)}</Code>
      </CodeContainer>
    </Container>
  )
}

export default ExampleWebhook
