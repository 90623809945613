import { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import ChevronIcon from './Chevron'

const Type = ({ type, descriptionHtml, showRequired }) => {
  const fields = (type.fields || []).concat(type.inputFields || [])

  return (
    <div>
      <h2 id={type.name}>{type.name}</h2>
      <p>{type.description}</p>
      {descriptionHtml ? <div dangerouslySetInnerHTML={{ __html: descriptionHtml }} /> : undefined}
      <FieldList>
        {fields.map((field) => {
          return <TopLevelField key={field.name} field={field} showRequired={showRequired} />
        })}
      </FieldList>
      <PossibleTypes type={type} />
    </div>
  )
}

export default Type

export const FieldList = styled.ul`
  list-style-type: none;
  margin-left: 0;
`

const ArgumentList = styled.ul`
  list-style-type: none;
  margin-left: 0;
  padding-left: 20px;
`

const DefaultValueValue = styled.span`
  font-family: monospace;
`

const DefaultValueTitle = styled.span`
  text-transform: uppercase;
  color: #65d3a8;
  font-weight: bolder;
`

const DefaultValue = styled(({ defaultValue, className }) => {
  if (defaultValue) {
    return (
      <span className={className}>
        <DefaultValueTitle>default</DefaultValueTitle> <DefaultValueValue>{defaultValue}</DefaultValueValue>
      </span>
    )
  } else {
    return null
  }
})`
  border-radius: 5px;
  padding: 3px;
  margin: 0 0px 0 13px;
`

const Required = styled(({ className }) => {
  return <span className={className}>required</span>
})`
  text-transform: uppercase;
  color: #ff88ab;
  font-weight: bolder;
  margin: 0 0px 0 13px;
`

export const Field = styled(({ field, className, showRequired }) => {
  const { name, link, required } = parseFieldType(field.type)
  return (
    <li className={className} id={field.name}>
      <FieldDeclaration>
        <FieldName>{field.name}</FieldName>:{' '}
        <TypeName>
          <Link to={link}>{name}</Link>
        </TypeName>
        <DefaultValue defaultValue={field.defaultValue} />
        {required && showRequired ? <Required /> : null}
      </FieldDeclaration>
      <p>{field.description}</p>
      {field.args?.length ? <Arguments args={field.args} /> : undefined}
    </li>
  )
})`
  margin: 0;
  padding: 16px;
`

const TopLevelField = styled(Field)`
  padding-bottom: 20px;
  border-bottom: 1px solid lightgrey;
`

const ArgumentsHeader = styled.span`
  margin-bottom: 0;
  cursor: pointer;
  font-weight: bold;
  .MuiSvgIcon-root {
    vertical-align: bottom;
  }
`

const Arguments = styled(({ className, args }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <ArgumentsHeader onClick={() => setIsOpen(!isOpen)}>
        Arguments
        <ChevronIcon direction={isOpen ? 'down' : 'right'} />
      </ArgumentsHeader>
      {isOpen ? (
        <ArgumentList className={className}>
          {args.map((arg) => {
            return <Field key={arg.name} field={arg} showRequired={true} />
          })}
        </ArgumentList>
      ) : undefined}
    </>
  )
})``

const FieldDeclaration = styled.span`
  font-family: monospace;
`

const FieldName = styled.span`
  font-family: monospace;
`

const TypeName = styled.span`
  font-family: monospace;

  a {
    color: inherit;
  }
`

const PossibleTypes = styled(({ className, type }) => {
  if (type.possibleTypes) {
    return (
      <div className={className}>
        <h2>Possible Types</h2>
        <ul>
          {type.possibleTypes.map((type) => (
            <li key={type.name}>
              <TypeName>
                <Link to={'/types/' + type.name}>{type.name}</Link>
              </TypeName>
            </li>
          ))}
        </ul>
      </div>
    )
  } else {
    return ''
  }
})``

function parseFieldType(fieldType) {
  switch (fieldType.kind) {
    case 'NON_NULL': {
      const { name, link } = parseFieldType(fieldType.ofType)
      return {
        required: true,
        name: `${name}!`,
        link: link,
      }
    }
    case 'LIST': {
      const { name, link } = parseFieldType(fieldType.ofType)
      return {
        required: false,
        name: `[${name}]`,
        link: link,
      }
    }
    default:
      return {
        required: false,
        name: fieldType.name,
        link: '/types/' + (fieldType.baseTypeName ? fieldType.baseTypeName + '#' + fieldType.name : fieldType.name),
      }
  }
}
