/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import ExampleQuery from '../../components/ExampleQuery';
import ExampleWebhook from '../../components/ExampleWebhook';
import {Type, FieldList} from '../../components/Schema';
import Layout from '../../components/Markdown';
const MDXLayout = Layout;
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    span: "span",
    p: "p",
    h2: "h2",
    code: "code",
    em: "em",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "webhooks"
  }, "Webhooks", React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#webhooks"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  }))), "\n", React.createElement(_components.p, null, "Webhooks can be used to receive realtime updates of changes made on the platform by customers and other users, as well as any automated processes such as payments and shipments. You can subscribe to these events by registering a webhook, which is an endpoint on your system that will receive a HTTP POST with a JSON body containing details of the event. The webhook subscription can specify which fields the webhook should receive using a regular GraphQL subscription query."), "\n", React.createElement(_components.p, null, "See ", React.createElement(_components.a, {
    href: "https://graphql.org/blog/subscriptions-in-graphql-and-relay/"
  }, "GraphQL Subscriptions"), " for a more general introduction to the concept."), "\n", React.createElement(_components.h2, {
    id: "create-a-webhook"
  }, "Create a webhook", React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#create-a-webhook"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  }))), "\n", React.createElement(_components.p, null, "To create a webhook subscription, use the ", React.createElement(_components.a, {
    href: "/types/Mutation/createWebhookSubscription"
  }, "createWebhookSubscription"), " mutation with the following fields:"), "\n", React.createElement(FieldList, {
    name: "CreateWebhookSubscriptionMutationInput",
    showRequired: true
  }), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "query"), " will subscribe to one or more event types detailed in ", React.createElement(_components.a, {
    href: "/types/GraphqlSubscription"
  }, "GraphqlSubscription"), " using the ", React.createElement(_components.code, null, "subscription"), " top level langauge construct."), "\n", React.createElement(ExampleQuery, {
    name: "createWebhookSubscription"
  }), "\n", React.createElement(_components.p, null, "Now, every time a new user is created, the endpoint at ", React.createElement(_components.code, null, "webhookUrl"), " will be sent a request containing the details of the new user, with the fields specified in the ", React.createElement(_components.code, null, "query"), ":"), "\n", React.createElement(ExampleWebhook, {
    name: "webhooks-userCreated-request"
  }), "\n", React.createElement(_components.h2, {
    id: "ping"
  }, "Ping", React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#ping"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  }))), "\n", React.createElement(_components.p, null, "When the subscription is created, or when you call ", React.createElement(_components.a, {
    href: "/types/Mutation/pingWebhookSubscription"
  }, "pingWebhookSubscription"), ", the platform will automatically send a ping request to the ", React.createElement(_components.code, null, "webhookUrl"), " endpoint, this can help debug connectivity issues."), "\n", React.createElement(ExampleWebhook, {
    name: "webhooks-ping-request"
  }), "\n", React.createElement(_components.p, null, "To check the result of a ping, you can query the webhook subscription:"), "\n", React.createElement(ExampleQuery, {
    name: "webhooks-query-webhook"
  }), "\n", React.createElement(_components.p, null, "As you can see, the response also contains other events sent to that subscription. These events are accessible for up to 7 days."), "\n", React.createElement(_components.h2, {
    id: "security"
  }, "Security", React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#security"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  }))), "\n", React.createElement(_components.p, null, "When the platform sends a request to a webhook endpoint, it also sends an ", React.createElement(_components.code, null, "Authorization"), " header that contains a ", React.createElement(_components.a, {
    href: "https://jwt.io/#debugger-io?token=eyJhbGciOiJIUzI1NiJ9.eyJib2R5U2lnbmF0dXJlIjoiN2I0YWZkMWY1YTdjMmI2ZWIxNDhiNGNlZjBkMjZlM2Q5YzAwNGU5OWFkYTQ5ZTkwNTZhNDEzZTNkOWFmYmM5YyIsImp0aSI6IjZWS0pDVyJ9.1ocR0PAjvgV9d4Tubq5tnqOMekqzQbWyOYUm_11TA-M"
  }, "JWT"), " with some details that you can use to ensure that the request did in fact come from the platform, and wasn't generated by another entity on the internet."), "\n", React.createElement(_components.p, null, "The token is signed using ", React.createElement(_components.code, null, "secret"), " that was used in the mutation to create subscription. Once the identity of token is verified, you can additionally check that the event request body hasn't been tampered with using ", React.createElement(_components.code, null, "bodySignature"), " of ", React.createElement(_components.em, null, "token"), " payload. ", React.createElement(_components.code, null, "bodySignature"), " contains SHA256 digest of the event request body."), "\n", React.createElement(_components.p, null, "Here is an example of a webhook request."), "\n", React.createElement(_components.p, null, "JWT from ", React.createElement(_components.code, null, "Authorization"), " header:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "eyJhbGciOiJIUzI1NiJ9.eyJib2R5U2lnbmF0dXJlIjoiMTBhZDk1M2YwY2VlODhkMDk1MjZmNmI5OWE5ZjZhMzI1MDBhNjgyN2NiMzEzMTliNzMzMTM4YmI5MWU0MTc3YyIsImp0aSI6Ik1EQjNDVyJ9.ozHNUVk6LCCmcdUDoB6MPFZOUHNShaPPF37C88PVp1g\n")), "\n", React.createElement(_components.p, null, "And the following request body:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "\"{\\\"data\\\":{\\\"userCreated\\\":{\\\"id\\\":\\\"User-42QF3KP37NW\\\",\\\"emailAddress\\\":\\\"daisy@example.com\\\"}},\\\"metadata\\\":{\\\"event\\\":{\\\"id\\\":\\\"WebhookSubscriptionEvent-MDB3CW\\\",\\\"timestamp\\\":\\\"2021-03-22T11:26:11Z\\\"},\\\"subscription\\\":{\\\"id\\\":\\\"WebhookSubscription-6L78CB\\\"}}}\"\n")), "\n", React.createElement(_components.p, null, "When the token is decoded, you get the following token payload:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n  \"bodySignature\": \"10ad953f0cee88d09526f6b99a9f6a32500a6827cb31319b733138bb91e4177c\",\n  \"jti\": \"MDB3CW\"\n}\n")), "\n", React.createElement(_components.p, null, "Now you can generate request body SHA256 digest and it should be identical to the above ", React.createElement(_components.code, null, "bodySignature"), ". As long as you verified the token's signature with ", React.createElement(_components.code, null, "very_secret"), " secret (the one that's been used in the ", React.createElement(_components.code, null, "createWebhookSubscription"), " mutation), this is a proof that the request body hasn't been tampered with."));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
