/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import ExampleQuery from '../../components/ExampleQuery';
import JsonObjectPath from '../../components/JsonObjectPath';
import Layout from '../../components/Markdown';
const MDXLayout = Layout;
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    span: "span",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "importing-contact-lens-prescriptions"
  }, "Importing Contact Lens Prescriptions", React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#importing-contact-lens-prescriptions"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  }))), "\n", React.createElement(_components.p, null, "Importing contact lens prescriptions requires both the customer and member of staff to have an account in Starfish.\nStaff accounts must be added through the Starfish application, but customer accounts can be created via the API. We\nalso require information about the product and the prescribing location. The steps below walk through fetching all\nthe required data before importing the contact lens prescription."), "\n", React.createElement(_components.h2, {
    id: "1-fetch-the-staff-member-from-starfish"
  }, "1. Fetch the staff member from Starfish", React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#1-fetch-the-staff-member-from-starfish"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  }))), "\n", React.createElement(_components.p, null, "Query the staffMember endpoint to get the staff member's Starfish ID. We can use this ID when the member of staff\nimports contact lens prescriptions via the API."), "\n", React.createElement(ExampleQuery, {
    name: "queryStaffMember",
    limitHeight: true
  }), "\n", React.createElement(_components.h2, {
    id: "2-fetch-the-customer-from-starfish"
  }, "2. Fetch the customer from Starfish", React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#2-fetch-the-customer-from-starfish"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  }))), "\n", React.createElement(_components.p, null, "If there is not already an account holder for this customer, follow the documentation ", React.createElement(_components.a, {
    href: "/types/Mutation/createAccountHolder"
  }, "here"), "\nto create one."), "\n", React.createElement(_components.p, null, "Query the AccountHolder endpoint to get the customer's Starfish ID. We'll pass this up in the request when importing\nthe customer's prescription."), "\n", React.createElement(ExampleQuery, {
    name: "queryAccountHolder",
    limitHeight: true
  }), "\n", React.createElement(_components.h2, {
    id: "3-fetch-the-product-from-starfish"
  }, "3. Fetch the product from Starfish", React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#3-fetch-the-product-from-starfish"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  }))), "\n", React.createElement(_components.p, null, "If we don't have the ID for the product, we can fetch that from the API."), "\n", React.createElement(ExampleQuery, {
    name: "queryProduct",
    limitHeight: true
  }), "\n", React.createElement(_components.h2, {
    id: "4-fetch-the-location-from-starfish"
  }, "4. Fetch the location from Starfish", React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#4-fetch-the-location-from-starfish"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  }))), "\n", React.createElement(_components.p, null, "If the prescription is an in-house prescription, you can pass this ID up when importing the customer's prescription.\nIf you don't have this location's Starfish ID, you can fetch it from the API:"), "\n", React.createElement(ExampleQuery, {
    name: "queryLocation",
    limitHeight: true
  }), "\n", React.createElement(_components.h2, {
    id: "5-import-the-contact-lens-prescription"
  }, "5. Import the contact lens prescription", React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#5-import-the-contact-lens-prescription"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  }))), "\n", React.createElement(_components.p, null, "Now we have the user's ID, the staff member's ID, the product ID, and the location ID, we can import the prescription\nvia the API following the documentation ", React.createElement(_components.a, {
    href: "/types/Mutation/createContactLensPrescription"
  }, "here"), "."));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
