/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import ExampleQuery from '../../components/ExampleQuery';
import JsonObjectPath from '../../components/JsonObjectPath';
import Layout from '../../components/Markdown';
const MDXLayout = Layout;
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    span: "span",
    p: "p",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "paging-through-products"
  }, "Paging Through Products", React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#paging-through-products"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  }))), "\n", React.createElement(_components.p, null, "There are a lot of products in the platform and for performance reasons we cannot download them all at once, but we can download them in batches, otherwise known as paging. The API uses a wellknown pattern for paging in GraphQL, you can read more on this ", React.createElement(_components.a, {
    href: "https://graphql.org/learn/pagination/#pagination-and-edges"
  }, "here"), "."), "\n", React.createElement(_components.p, null, "To load the first page, simply ask for the products."), "\n", React.createElement(ExampleQuery, {
    name: "pagingThroughProducts1",
    limitHeight: true
  }), "\n", React.createElement(_components.p, null, "You'll notice that we only show the first ", React.createElement(JsonObjectPath, {
    file: "pagingThroughProducts1",
    path: "response.data.products.nodes.length"
  }), " products, to get the next page, we need to take the ", React.createElement(_components.code, null, "endCursor"), " and pass it to the next call to ", React.createElement(_components.code, null, "products"), " as the ", React.createElement(_components.code, null, "after"), " argument."), "\n", React.createElement(ExampleQuery, {
    name: "pagingThroughProducts2",
    limitHeight: true
  }), "\n", React.createElement(_components.p, null, "You can keep paging through the products until the ", React.createElement(_components.code, null, "hasNextPage"), " field becomes ", React.createElement(_components.code, null, "false"), ", at that point you've enumerated all the products."));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
