import styled from 'styled-components'

const Chevron = ({ direction = 'right', className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>chevron-{direction}</title>
      <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
    </svg>
  )
}

const ChevronIcon = styled(Chevron)`
  vertical-align: bottom;
  transform: ${({ direction }) => (direction === 'down' ? 'rotate(90deg)' : 'none')};
  transition: transform 0.3s ease;
  width: 27px;
  height: 27px;
  fill: currentColor;
`

export default ChevronIcon
