/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import ExampleQuery from '../../components/ExampleQuery';
import JsonObjectPath from '../../components/JsonObjectPath';
import Layout from '../../components/Markdown';
const MDXLayout = Layout;
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    span: "span",
    p: "p",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "validation-errors"
  }, "Validation Errors", React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#validation-errors"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  }))), "\n", React.createElement(_components.p, null, "When creating or modifying entities in the system using the API you may encounter validation errors. Each validation error will identify the field used in the mutation input, and the problem with it. Make sure to query for ", React.createElement(_components.code, null, "errors { path message }"), " with each mutation."), "\n", React.createElement(ExampleQuery, {
    name: "createContactLensPrescription-validation-error"
  }));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
