import schemaModule from '../generated/schema'
import { keyBy } from 'lodash'
import TypeType, { Field, FieldList as TypeFieldList } from './Type'

const { __schema: schema } = schemaModule

const removeClientMutationId = (fields) => {
  return fields.filter((field) => field.name !== 'clientMutationId')
}

schema.types.forEach((type) => {
  if (type.fields) {
    type.fields = removeClientMutationId(type.fields)
  }

  if (type.inputFields) {
    type.inputFields = removeClientMutationId(type.inputFields)
  }
})

const types = keyBy(schema.types, (t) => t.name)

export const Type = ({ name, ...props }) => {
  return <TypeType type={types[name]} {...props} />
}

export const FieldList = ({ name, ...props }) => {
  const type = types[name]
  const fields = (type.fields || []).concat(type.inputFields || [])

  return (
    <TypeFieldList>
      {fields.map((field) => {
        return <Field key={field.name} field={field} {...props} />
      })}
    </TypeFieldList>
  )
}
