/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import LocalUrl from '../components/LocalUrl';
import Layout from '../components/Markdown';
const MDXLayout = Layout;
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    span: "span",
    p: "p",
    h2: "h2",
    h3: "h3",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "introduction"
  }, "Introduction", React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#introduction"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  }))), "\n", React.createElement(_components.p, null, "The Starfish API gives access to the most important information within the Starfish platform, allowing clients to synchronise account and prescription information between Starfish and their own PMS or eCommerce system; create new accounts and upload prescriptions to help customers purchase prescription items online."), "\n", React.createElement(_components.p, null, "The platform also generates events which can be subscribed to using webhooks and the API will send your application as much or as little detail required to process the event in the client system."), "\n", React.createElement(_components.h2, {
    id: "graphql"
  }, "GraphQL", React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#graphql"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  }))), "\n", React.createElement(_components.p, null, "As a GraphQL API it has several advantages over Rest APIs in that you can fetch as much or as little information as you need from the API. Some familiarity with GraphQL is recommended, and we provide some examples of common interactions, for example ", React.createElement(_components.a, {
    href: "/scenarios/paging-products"
  }, "Paging"), " or ", React.createElement(_components.a, {
    href: "/types/Mutation/createUser"
  }, "creating users"), "."), "\n", React.createElement(_components.p, null, "We also provide a ", React.createElement("a", {
    href: "starfish_api.postman_collection.json",
    download: true
  }, "Postman collection"), " that includes some example queries for you to test our API with. For this to work you will need to provide your API token and the endpoint for the API. You can acquire both of these by following the steps below."), "\n", React.createElement(_components.h3, {
    id: "endpoint"
  }, "Endpoint", React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#endpoint"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  }))), "\n", React.createElement(_components.p, null, "The API is available to both EU and US clients"), "\n", React.createElement(_components.p, null, "For EU clients:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "https://<your client subdomain>.indigoshoprx.co.uk/graphql/v1\n")), "\n", React.createElement(_components.p, null, "For US clients:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "https://<your client subdomain>.indigoshoprx.com/graphql/v1\n")), "\n", React.createElement(_components.p, null, "In non-production environments, there is also an interactive GraphiQL installation that you can use to make requests to the API from the web browser:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "https://<your client subdomain>.demo.indigolighthouseomnichannel.com/graphiql\n")), "\n", React.createElement(_components.h3, {
    id: "authentication"
  }, "Authentication", React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#authentication"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  }))), "\n", React.createElement(_components.p, null, "To use API you need an api user. You can create one in control panel (Control panel -> Staff users -> API users). Take a note of an API token when user is created - you'll need to add it to the ", React.createElement(_components.code, null, "Authorization"), " header when making requests to the API:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "Authorization: Bearer <token>\n")), "\n", React.createElement(_components.h3, {
    id: "authorization"
  }, "Authorization", React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#authorization"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  }))), "\n", React.createElement(_components.p, null, "Some queries and mutations are restricted to certain API level access based on your contract length with Starfish. You will find the following note when it's the case: ", React.createElement(_components.code, null, "Requires advanced API access level"), ". Please contact your company's contract manager to discuss your requirements."));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
