import styled from 'styled-components'
import ExampleQuery from '../components/ExampleQuery'

const ExampleLayout = ({ className, exampleName, children }) => (
  <Container>
    <Body>{children}</Body>
    <Example name={exampleName} ignoreLoadError={true} />
  </Container>
)

const Body = styled.div``

const Example = styled(ExampleQuery)``

const Container = styled.div`
  display: flex;
  justify-content: center;

  & > * {
    margin-right: 20px;
    min-width: 500px;
    max-width: 800px;
    flex: 1 1 0px;
  }
`

export default ExampleLayout
