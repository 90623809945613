import objectPath from 'object-path'

const JsonObjectPath = ({ file, path }) => {
  const object = require(`../generated/examples/${file}.json`)
  const result = objectPath.get(object, path)

  if (result === undefined) {
    return null
  } else {
    return result
  }
}

export default JsonObjectPath
