import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Header = styled(({ siteTitle, className }) => (
  <header className={className}>
    <HeaderTitle className="small">
      <Link
        to="/"
        style={{
          color: `white`,
          textDecoration: `none`,
        }}
      >
        {siteTitle}
      </Link>
    </HeaderTitle>
  </header>
))`
  background: rgb(59, 0, 129);
  background: linear-gradient(301deg, rgba(59, 0, 129, 1) 0%, rgba(4, 0, 91, 1) 100%);
  padding: 1.45rem 1.0875rem;
`

const HeaderTitle = styled.h1`
  font-size: 1rem;
  margin: 0;
`

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
