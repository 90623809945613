import { Link, navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql, withPrefix } from 'gatsby'
import { useState, useEffect } from 'react'
import { keyBy, sortBy } from 'lodash'
import styled from 'styled-components'
import ChevronIcon from './Chevron'

const readMenus = (data) => {
  const menus = data.allMdx.nodes.map(({ fields, frontmatter }) => ({
    title: frontmatter.title,
    menu: frontmatter.menu,
    route: fields.slug,
  }))

  const typeMenus = data.allSitePage.nodes.map(({ path, pageContext }) => ({
    title: pageContext.title,
    menu: pageContext.menu,
    route: path,
  }))

  const topLevel = createMenuHierarchy(menus)
  const typesMenu = createMenuHierarchy(typeMenus)
  const queryMenu = typesMenu.find((m) => m.title === 'Query')
  const mutationMenu = typesMenu.find((m) => m.title === 'Mutation')

  topLevel.push(queryMenu, mutationMenu)
  topLevel.push({
    title: 'Types',
    subMenus: typesMenu.filter((m) => ![queryMenu, mutationMenu].includes(m)),
  })

  return topLevel
}

const createMenuHierarchy = (menus) => {
  const menusByTitle = keyBy(menus, (m) => m.title)

  const topLevelMenus = []

  menus.forEach((menu) => {
    if (menu.menu) {
      let parentMenu = menusByTitle[menu.menu]

      if (!parentMenu) {
        parentMenu = {
          title: menu.menu,
        }
        topLevelMenus.push(parentMenu)
        menusByTitle[parentMenu.title] = parentMenu
      }

      parentMenu.subMenus = parentMenu.subMenus || []
      parentMenu.subMenus.push(menu)
    } else {
      topLevelMenus.push(menu)
      menusByTitle[menu.title] = menu
    }
  })

  Object.values(menusByTitle).forEach((menu) => {
    if (menu.subMenus) {
      menu.subMenus = sortBy(menu.subMenus, (m) => m.route)
    }
  })

  return sortBy(topLevelMenus, (m) => m.route)
}

const MenuComponent = styled(({ className }) => {
  const data = useStaticQuery(graphql`
    query HeadingQuery {
      allMdx {
        nodes {
          fields {
            slug
          }
          frontmatter {
            title
            menu
          }
        }
      }
      allSitePage(filter: { context: { isApiNode: { eq: true } } }) {
        nodes {
          path
          pageContext
        }
      }
    }
  `)

  const menus = readMenus(data)

  return (
    <header className={className}>
      <MenuSearch menus={menus} />
    </header>
  )
})`
  display: flex;
  flex-direction: column;
`

export default MenuComponent

let currentSearch = ''

const MenuSearch = ({ menus }) => {
  const [input, setInput] = useState(currentSearch)

  useEffect(() => () => {
    currentSearch = input
  })

  if (input) {
    menus = flattenMenus(menus).filter((menu) => {
      if (menu.subMenus) return false

      return input
        .split(' ')
        .filter(Boolean)
        .every((part) => menu.title.match(new RegExp(escapeRegExp(part), 'i')))
    })
  }

  const navigateOnEnter = (e) => {
    if (e.charCode === 13 && menus.length) {
      navigate(menus[0].route)
    }
  }

  return (
    <>
      <MenuSearchInput value={input} onInput={(e) => setInput(e.target.value)} onKeyPress={(e) => navigateOnEnter(e)} />
      <Menu menus={menus} />
    </>
  )
}

const MenuSearchInput = styled(({ value, onInput, className, onKeyPress }) => {
  return (
    <input
      className={className}
      type="search"
      placeholder="Search for a type, mutation, ..."
      value={value}
      onInput={onInput}
      onKeyPress={onKeyPress}
    ></input>
  )
})`
  margin: 10px;
`

function flattenMenus(menus) {
  return menus.flatMap((menu) => {
    return [menu].concat(flattenMenus(menu.subMenus || []))
  })
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

const Menu = styled(({ menus, className }) => {
  const location = useLocation()

  return (
    <ul className={className}>
      {menus.map((menu) => {
        const isCurrent = withPrefix(menu.route) === location.pathname

        return (
          <MenuItem key={`${menu.title}:${menu.route}`}>
            {menu.subMenus ? (
              <MenuAccordion menu={menu} subMenus={menu.subMenus} />
            ) : (
              <MenuItemLink menu={menu} isCurrent={isCurrent} />
            )}
          </MenuItem>
        )
      })}
    </ul>
  )
})`
  list-style-type: none;
  margin-left: 20px;
  margin: 10px;
`

const MenuItem = ({ ...props }) => {
  return <li {...props}></li>
}

const MenuItemLink = styled(({ menu, className, ...props }) => {
  return (
    <Link className={className} to={menu.route}>
      {menu.title}
    </Link>
  )
})`
  background-color: ${({ isCurrent }) => (isCurrent ? 'rgba(0, 0, 0, 0.1)' : 'transparent')};
  border-radius: 3px;
  text-decoration: none;
  color: inherit;
`

const allSubMenuRoutes = (menu) => {
  return [withPrefix(menu.route)].concat((menu.subMenus || []).flatMap(allSubMenuRoutes))
}

const MenuAccordionTitle = styled.span`
  cursor: pointer;
  .MuiSvgIcon-root {
    vertical-align: bottom;
  }
`

const MenuAccordion = ({ menu, subMenus }) => {
  const location = useLocation()
  const isCurrent = allSubMenuRoutes(menu).includes(location.pathname)
  const [isOpen, setIsOpen] = useState(isCurrent)

  return (
    <>
      <MenuAccordionTitle onClick={() => setIsOpen(!isOpen)}>
        {menu.title}
        <ChevronIcon direction={isOpen ? 'down' : 'right'} />
      </MenuAccordionTitle>
      {isOpen ? <Menu menus={subMenus} /> : undefined}
    </>
  )
}
